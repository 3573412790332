<template>
<p>Les étudiants peuvent répondre à la question posée en donnant la réponse qu'ils souhaitent.</p>
</template>

<script>
export default {
   name: "openEndedResponse"
};
</script>

<style scoped>

</style>